import React from "react";

import createPersistedState from "use-persisted-state";

import Badge from "./../Badge";

import { PRIZE_TRESHOLD, TOTAL_BADGES } from "./constants";
import STYLES from "./styles.module.css";

const useBadgesState = createPersistedState("badges");

function App() {
  const [count, setCount] = useBadgesState(0);

  const isWinner = count >= PRIZE_TRESHOLD;
  const reset = () => setCount(0);
  const increment = () => setCount(count + 1);

  return (
    <div className={STYLES.container}>
      <div className={STYLES.header}>
        <h1>Persistance Reward</h1>
      </div>
      {!isWinner && <Badges increment={increment} count={count} />}
      {isWinner && <WinnerMessage />}
      <div className={STYLES.reset}>
        <button onClick={reset}>RESET</button>
      </div>
    </div>
  );
}
function Badges({ increment, count }) {
  return (
    <div className={STYLES.content}>
      {Array(TOTAL_BADGES)
        .fill()
        .map((_, index) => (
          <Badge
            key={`${index}`}
            disabled={index < count}
            onClick={increment}
          />
        ))}
    </div>
  );
}
function WinnerMessage() {
  return (
    <div className={STYLES.winner}>
      <h1>
        You DID IT!!
        <span role="img" aria-label="tada">
          🎉
        </span>
        <span role="img" aria-label="tada">
          🎉
        </span>
        <span role="img" aria-label="tada">
          🎉
        </span>
      </h1>
    </div>
  );
}
export default App;
