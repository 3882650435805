import React from "react";

import classnames from "classnames";

import STYLES from "./styles.module.css";

function Badge({ disabled, onClick }) {
  return (
    <div
      className={classnames({
        [STYLES.badge]: true
      })}
      onClick={disabled ? () => {} : onClick}
    >
      {!disabled && (
        <span role="img" aria-label="tada">
          💥
        </span>
      )}
      {disabled && (
        <span role="img" aria-label="tada">
          ✅
        </span>
      )}
    </div>
  );
}

export default Badge;
